import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'
import { get, replace } from 'lodash-es'

export default class extends Controller {
  static targets = ['display', 'matrix', 'input', 'suffix', 'label']
  static values = {
    enableX: Boolean
  }

  connect() {
    useClickOutside(this)
  }

  toggleSelector(e) {
    e.preventDefault()

    if (this.hasMatrixTarget) {
      this.matrixTarget.classList.toggle('hide')
    }
  }

  hideSelector(e) {
    e.preventDefault()

    if (!this.hasMatrixTarget || e.currentTarget == this.matrixTarget) {
      return
    }

    this.matrixTarget.classList.add('hide')
  }

  onSelect(e) {
    e.preventDefault()

    const grade = get(e, 'currentTarget.dataset.grade', '')
    const priority = get(e, 'currentTarget.dataset.priority', '')
    this.showSelectedLabel(grade)

    if (grade.length == 0) {
      console.error('no grade selected')
      return
    }

    const gradePriority = `${grade}${priority}`

    if (this.hasInputTarget) {
      this.inputTarget.value = `${gradePriority}`
    }

    if (this.hasDisplayTarget) {
      const scoreEl = this.displayTarget.querySelector('.score')
      scoreEl.textContent = gradePriority

      const conditionGradeEl = this.displayTarget.querySelector('.condition-grade')
      let classList = conditionGradeEl.classList
      while (classList.length > 0) {
        classList.remove(classList.item(0));
      }
      classList.add('condition-grade', `${grade.toLowerCase()}${priority}`)
    }

    if (this.enableXValue && this.hasSuffixTarget) {
      const label = this.suffixTarget.closest('label')
      if (grade == 'C' || grade == 'D') {
        this.suffixTarget.disabled = false
        label.classList.remove('hide')
        this.setObsolete(this.suffixTarget.checked)
      } else {
        label.classList.add('hide')
        this.suffixTarget.disabled = true
        this.suffixTarget.checked = false
        this.setObsolete(false)
      }
    }

    if (this.hasMatrixTarget) {
      this.matrixTarget.classList.add('hide')
    }

  }

  onXChange(e) {
    if (!this.enableXValue) {
      return
    }

    if (e.currentTarget.checked) {
      this.setObsolete(true)
    } else {
      this.setObsolete(false)
    }
  }

  setObsolete(val) {
    const suffixEl = this.displayTarget.querySelector('.suffix')
    const conditionGradeEl = this.displayTarget.querySelector('.condition-grade')
    let newGradePriority
    if (val) {
      newGradePriority = this.inputTarget.value + 'X'
      suffixEl.textContent = 'x'
      conditionGradeEl.classList.add('x')
    } else {
      newGradePriority = replace(this.inputTarget.value, 'X', '')
      suffixEl.textContent = ''
      conditionGradeEl.classList.remove('x')
    }

    this.inputTarget.value = newGradePriority
  }

  showSelectedLabel(grade) {
    if (!this.hasLabelTarget) {
      console.log('no label')
      return false
    }

    let labelGrade
    console.log(`showSelectedLabel ${grade}`)
    this.labelTargets.forEach((labelElem) => {
      labelGrade = get(labelElem, 'dataset.grade')
      console.log(`labelGrade ${labelGrade}`)
      if (labelGrade && labelGrade == grade) {
        labelElem.classList.remove('d-none')
      } else {
        labelElem.classList.add('d-none')
      }
    })
  }

}