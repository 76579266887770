import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { after: { type: Number, default: 10 } }

  connect() {
    setTimeout(
      () => this.element.remove(),
      this.afterValue * 1000
    )
  }
}
