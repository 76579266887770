import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { elementId: String }

  open(e) {
    e.preventDefault()
    const element = document.getElementById(this.elementIdValue);

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) { /* Safari */
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) { /* IE11 */
      element.msRequestFullscreen();
    }
  }
}
