export function requestSubmit(form) {
  let submitButton = form.querySelector('button[type=submit]');

  if (form.requestSubmit) {
    // Normal case: non-Safari browsers
    form.requestSubmit();

  } else if(submitButton) {
    // Safari and the form has a submit button
    submitButton.click();

  } else {
    // Safari and no submit button
    submitButton = document.createElement('button');
    submitButton.type = 'submit';
    submitButton.style.display = 'none';
    submitButton.dataset.sythentic = 'true';
    form.insertAdjacentElement('beforeend', submitButton);

    submitButton.click();
  }
}
