import {BaseController} from "stimulus-library";

/*
 * The `replace(event)` action replaces the contents of the block
 * target with HTML returned from its `event.target.url`, then scrolls
 * heading target into view
 *
 * To use it with kaminari pagination, use the templates that link here, with
 * ```
 * paginate @completion_records, param_name: 'completed_page', views_prefix: 'ajax'
 * ```
 *
 * Error recovery is based on loading event.target.url so configure the server to respond with a full page when not XHR.
 */
export default class FetchBlockController extends BaseController {
  static targets = ['block', 'heading'];

  async replace(event) {
    const url = event.target.href;
    event.preventDefault();

    try {
      this.blockTarget.innerHTML = `<div class="p-3 text-center"> <i class="fa fa-spinner fa-spin"></i> Loading ...</div>`;

      if (url === document.URL) {
        return;
      }

      const html = await fetch(
        url,
        {headers: {'X-Requested-With': 'XMLHttpRequest'}},
      );

      if (html.ok) {
        window.history.pushState({}, document.title, url);
        this.blockTarget.outerHTML = await html.text();
        this.headingTarget.scrollIntoView();
      } else {
        location.assign(url);
      }
    } catch (error) {
      location.assign(url);
    }
  }
}
