import { Controller } from '@hotwired/stimulus';
import { useIntersection } from "stimulus-use";

export default class extends Controller {

  initialize() {
    this.eventHandler = this.eventHandler.bind(this);
  }

  connect() {
    let _ignore
    [_ignore, this.unobserveIntersection] = useIntersection(this);

    requestAnimationFrame(() => {
      let target = this.element;
      target.style.resize = 'none';
      target.style.boxSizing = 'border-box';

      this.eventHandler();
      window.addEventListener('resize', this.eventHandler);
      target.addEventListener('input', this.eventHandler);
      target.addEventListener('focus', this.eventHandler);
    });
  }

  disconnect() {
    window.removeEventListener('resize', this.eventHandler);
    let target = this.element;
    target.removeEventListener('input', this.eventHandler);
    target.removeEventListener('focus', this.eventHandler);
  }

  eventHandler() {
    this.autosize(this.element);
  }

  appear(_entry) {
    this.autosize(this.element);
    this.unobserveIntersection();
  }

  autosize(element) {
    let offset = element.offsetHeight - element.clientHeight;
    element.style.height = 'auto';
    element.style.height = element.scrollHeight + offset + 'px';
  }

}
