import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['button', 'content']
  static values = {
    autoCollapse: Boolean
  }

  buttonTargetConnected(button) {
    button.addEventListener('click', this.selectSection.bind(this))
  }

  selectSection(e) {
    const parent = e.currentTarget.closest('.accordion-section')
    parent.classList.toggle('active')
    if (this.autoCollapseValue == true && parent.classList.contains('active')) {
      this.element.querySelectorAll('.accordion-section.active').forEach((elem) => {
        if (elem == parent) {
          return
        }

        elem.classList.remove('active')
      })
    }
  }
}
