import { Controller } from "@hotwired/stimulus"
import { debounce, defaultTo, includes, orderBy, partition, toString } from "lodash-es"

export default class extends Controller {
  static targets = ["content", "headers"]

  initialize() {
    this.filter = debounce(this.filter, 200).bind(this)
  }

  sort(event) {
    event.preventDefault()
    event.stopPropagation()

    const sortAttr = event.target.dataset.sort
    const sortDataType = event.target.dataset.dataType
    let direction = defaultTo(event.target.getAttribute("data-direction"), "asc")
    const switchDirection = event.target.classList.contains("active")
    if (switchDirection) {
      direction = direction == "desc" ? "asc" : "desc"
    }

    const icon = event.target.querySelector("i.fa")
    if (icon) {
      if (direction == "desc") {
        icon.classList.remove("fa-caret-up")
        icon.classList.add("fa-caret-down")
      } else {
        icon.classList.remove("fa-caret-down")
        icon.classList.add("fa-caret-up")
      }
    }
    event.target.setAttribute("data-direction", direction)

    this.sortItemsBy(sortAttr, direction, sortDataType)

    event.target.parentNode.querySelectorAll(".active").forEach((el) => el.classList.remove("active"))
    event.target.classList.add("active")
  }

  filter(event) {
    event.preventDefault()
    event.stopPropagation()

    this.removeEmptyMessage()

    const filter = event.target.value.toLowerCase().trim()
    if (filter == "") {
      this.contentTarget.children.forEach((el) => el.classList.remove("is-hidden"))
      return
    }

    const filterAttr = event.target.getAttribute("data-sortable-filter")
    let ref
    this.contentTarget.children.forEach((el) => {
      ref = toString(el.getAttribute(`data-sortable-${filterAttr}`)).toLowerCase()
      if (includes(ref, filter)) {
        el.classList.remove("is-hidden")
      } else {
        el.classList.add("is-hidden")
      }
    })

    this.applyCurrentSort()
    if (this.visibleItems.length == 0) {
      this.showEmptyMessage()
    }
  }

  applyCurrentSort() {
    let currentSortEl = this.headersTarget.querySelector("[data-sort].active")
    if (!currentSortEl) {
      currentSortEl = document.querySelector("[data-sort]")
    }
    if (!currentSortEl) {
      return
    }

    const sortAttr = currentSortEl.dataset.sort
    let direction = defaultTo(currentSortEl.getAttribute("data-direction"), "asc")
    this.sortItemsBy(sortAttr, direction)
  }

  sortItemsBy(sortAttr, direction, dataType = null) {
    let _parts = partition([...this.contentTarget.children], function (node) {
      return node.classList.contains("is-hidden")
    })
    let hidden = _parts[0]
    this.visibleItems = orderBy(
      _parts[1],
      [
        function (node) {
          return node.getAttribute('data-sortable-fixed')
        },
        function (node) {
          const val = node.getAttribute(`data-sortable-${sortAttr}`)
          if (dataType && dataType == 'numeric') {
            return Number(val)
          }
          return val
        }
      ],
      ['asc', direction]
    )

    this.visibleItems.forEach((node) => this.contentTarget.appendChild(node))
    hidden.forEach((node) => this.contentTarget.appendChild(node))
  }

  removeEmptyMessage() {
    let emptyMessage = this.contentTarget.querySelector(".empty-results")
    if (!emptyMessage) {
      return
    }

    emptyMessage.remove()
  }

  showEmptyMessage() {
    let emptyMessage = document.createElement("div")
    emptyMessage.innerHTML = "No results found"
    emptyMessage.classList.add("empty-results")
    this.contentTarget.appendChild(emptyMessage)
  }
}
