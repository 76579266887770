import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    frameId: String
  }

  connect() {
    this.refresh()
  }

  refresh() {
    if (this.hasFrameIdValue) {
      const frame = document.querySelector(`#${this.frameIdValue}`)
      if (!frame) {
        console.error(`frame not found with id: ${this.frameIdValue}`)
        return
      }

      const src = frame.getAttribute('src')
      if (!src) {
        console.error(`unable to refresh frame with id ${this.frameIdValue}: no src attribute`)
        return
      }

      frame.setAttribute('src', '')
      frame.setAttribute('src', src)
    }
  }
}
