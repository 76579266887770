// This controller allow you to have a set of radio buttons in a form that can show a correspondingly
// identified element and enable its inputs.
// All other elements identified by the other radio buttons will be hidden and inputs disabled.

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["option", "content"]

  connect() {
    this.select();
  }

  select() {
    const selectedOption = this.optionTargets.find(o => o.querySelector('input[type="radio"]').checked);
    const identifier = selectedOption ? selectedOption.dataset.revealIdentifier : null;

    // Hide and disable the corresponding element and its inputs when its radio option is not checked
    this.contentTargets.forEach((elem) => {
      if (elem.dataset.revealIdentifier === identifier) {
        elem.classList.remove("hide");
        this.enableInputsFor(elem, true);
      } else {
        elem.classList.add("hide");
        this.enableInputsFor(elem, false);
      }
    })
  }

  enableInputsFor(elem, shouldEnable) {
    // If any other input types need disabling when the corresponding radio button is not checked
    // then add them here
    const inputs = elem.querySelectorAll('input, select, textarea');
    inputs.forEach(input => {
      input.disabled = !shouldEnable;
    });
  }
}
