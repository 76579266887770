import { Controller } from "@hotwired/stimulus"
import { find, get } from 'lodash-es'

export default class extends Controller {

  static targets = ['tab', 'content']
  static values = {
    navigationEnabled: Boolean
  }

  connect() {
    this.tabTargets.forEach((tab) => {
      tab.addEventListener('click', this.selectTab.bind(this))
    })

    if (this.navigationEnabledValue && location.hash) {
      const tabTarget = find(this.tabTargets, (tab) => { return tab.dataset.tabId == location.hash.replace('#', '') })
      if (tabTarget) {
        tabTarget.click()
      }
    }
  }

  selectTab(e) {
    this.tabTargets.forEach((tab) => {
      if (tab == e.currentTarget) {
        tab.classList.add('active')
        this.activateContent(tab.dataset.contentId)
        this.setNavigationHash(tab.dataset.tabId)
      } else {
        tab.classList.remove('active')
      }
    })
  }

  activateContent(contentId) {
    this.contentTargets.forEach((elem) => {
      if (elem.getAttribute('id') == contentId) {
        elem.classList.add('active')
      } else {
        elem.classList.remove('active')
      }
    })
  }

  setNavigationHash(tabId) {
    if (this.navigationEnabledValue) {
      window.history.pushState(null, null, `#${tabId}`)
    }
  }

}
