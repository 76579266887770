import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    document.addEventListener('turbo:frame-missing', this.autoRedirect)
  }

  autoRedirect(event) {
    // Visit the response
    event.preventDefault()
    event.detail.visit(event.detail.response)
  }

}