// app/javascript/controllers/date_input_mask_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    format: String,
    mask: String
  }

  connect() {
    this.element.addEventListener('keyup', (event) => this.handleInput(event));
    this.element.addEventListener('keydown', (event) => this.handleInput(event));
  }

  handleInput(event) {
    const isDeletion = event.key === "Backspace" || event.key === "Delete";
    const isNumber = event.key >= "0" && event.key <= "9";

    if (isDeletion || isNumber) {
      this.format(isDeletion);
    }
  }

  format(isDeletion) {
    const elem = this.element;
    const oldValue = elem.value;
    const val = this.doFormat(elem.value, this.formatValue, this.maskValue, isDeletion);
    elem.value = val;

    if (!isDeletion && oldValue.length < elem.value.length) {
      this.setCursorPosition(elem, val.length);
    }
  }

  setCursorPosition(elem, position) {
    if (elem.createTextRange) {
      var range = elem.createTextRange();
      range.move('character', position);
      range.select();
    } else if (elem.selectionStart) {
      elem.focus();
      elem.setSelectionRange(position, position);
    }
  }

  doFormat(x, pattern, mask, isDeletion) {
    var strippedValue = x.replace(/[^0-9]/g, "");
    var chars = strippedValue.split('');
    var count = 0;

    var formatted = '';
    for (var i = 0; i < pattern.length; i++) {
      const c = pattern[i];
      if (chars[count]) {
        if (/\*/.test(c)) {
          formatted += chars[count];
          count++;
        } else {
          formatted += c;
        }
      } else if (!isDeletion && mask) {
        if (mask.split('')[i])
          formatted += mask.split('')[i];
      }
    }
    return formatted;
  }
}
