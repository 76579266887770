import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    delay: Number
  }

  connect() {
    if (this.hasDelayValue) {
      setTimeout(this.triggerRefresh.bind(this), this.delayValue)
    } else {
      this.triggerRefresh()
    }
  }

  triggerRefresh() {
    let event = new CustomEvent('trigger-refresh', { detail: this.element })
    window.dispatchEvent(event)
  }
}
