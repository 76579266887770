import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["links", "template"]

  connect() {
    this.wrapperSelector = this.element.dataset.wrapperSelector || ".nested-fields"
    this.containerSelector = this.element.dataset.containerSelector || '.nested-container'
    this.keepRemovedHidden()
    this.updateCounts()
    this.updateRemoves()
  }

  add(event) {
    event.preventDefault()

    let new_item = this.templateTarget.content.firstElementChild.cloneNode(true)
    new_item.innerHTML = new_item.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())

    let container = this.element.querySelector(this.containerSelector)
    container.appendChild(new_item)

    let inputs = new_item.querySelectorAll('input[type="text"]')
    inputs[inputs.length - 1].focus()

    this.updateCounts()
    this.updateRemoves()
  }

  remove(event) {
    event.preventDefault()

    if (!confirm("Are you sure?")) { return }

    let wrapper = event.target.closest(this.wrapperSelector)

    // New records are simply removed from the page
    if ("new" in wrapper.dataset) {
      wrapper.remove()

      // Existing records are hidden and flagged for deletion
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = 'none'
    }

    this.updateCounts()
    this.updateRemoves()
  }

  updateCounts() {
    let count = 1
    let wrappers = this.element.querySelectorAll(this.wrapperSelector)
    wrappers.forEach((wrapper) => {
      let countLabel = wrapper.querySelector('[data-count]')
      if (countLabel) {
        countLabel.innerHTML = count
        count += 1
      }
    })
  }

  updateRemoves() {
    let removes = this.element.querySelectorAll('[data-remove]')
    const num_items = removes.length

    if (num_items == 1) {
      this.element.querySelector('[data-remove]').classList.add('hide')
    } else {
      removes.forEach((item) => {
        item.classList.remove('hide')
      })
    }
  }

  keepRemovedHidden() {
    const removes = this.element.querySelectorAll('[data-remove]')
    removes.forEach((item) => {
      let wrapper = item.closest(this.wrapperSelector)
      let hiddenDestroyField = wrapper.querySelector("input[name*='_destroy']")
      if (hiddenDestroyField.value == '1' || hiddenDestroyField.value == 'true') {
        wrapper.style.display = 'none'
      }
    })
  }
}
