import { Controller } from '@hotwired/stimulus'
import { get, find } from 'lodash-es'

export default class extends Controller {

  static targets = ['row', 'newRow']

  addRow(e) {
    const rowTarget = this.newRowTarget
    if (!rowTarget) {
      console.error('new row target not found')
      return false
    }
    this.cloneRow(rowTarget)
  }

  removeRow(e) {
    const rowTarget = this.findRowTarget(e.target)
    if (!rowTarget) {
      console.error('row target not found')
      return false
    }
    rowTarget.remove()
  }

  findRowTarget(targetElement) {
    return find(this.rowTargets, (elem) => {
      return elem == get(targetElement, 'parentNode')
    })
  }

  cloneRow(rowTarget) {
    const newRow = rowTarget.cloneNode(true)

    newRow.classList.remove('hide')
    const targetKey = `data-${this.identifier}-target`
    newRow.setAttribute(targetKey, 'row')
    rowTarget.before(newRow)
    const removeRowButton = newRow.querySelector('[data-remove-row-button]')
    if (removeRowButton) {
      removeRowButton.classList.remove('invisible')
    }
  }
}