import { Controller } from '@hotwired/stimulus'
import { debounce, get } from 'lodash-es'

export default class extends Controller {

  static targets = ['hiddenInput', 'userInput']
  static values = {
    disableDecimals: Boolean
  }

  initialize() {
    this.onChange = debounce(this.onChange.bind(this), 1000)
  }

  connect() {
    if (!this.hasUserInputTarget || !this.hasHiddenInputTarget) {
      console.error('unable to process currency input')
      return
    }

    if (this.hiddenInputTarget.value != '') {
      this.formatUserInput(this.hiddenInputTarget.value)
    }
  }

  onChange() {
    if (!this.hasUserInputTarget || !this.hasHiddenInputTarget) {
      return
    }

    let total = this.userInputTarget.value.replace(/[^0-9\.]/g, '')
    if (total.length == 0) {
      this.hiddenInputTarget.value = 0
      this.triggerFormChange()
      return
    }

    total = this.decimalsEnabled() ? parseFloat(total).toFixed(2) : parseInt(total)
    this.formatUserInput(total)
    this.hiddenInputTarget.value = total
    this.triggerFormChange()
  }

  formatUserInput(total) {
    const showDecimals = this.decimalsEnabled() && ((total - Math.floor(total)) !== 0)
    const formatOpts = showDecimals ? { minimumFractionDigits: 2, maximumFractionDigits: 2 } : {}
    this.userInputTarget.value = Number(total).toLocaleString('en-GB', formatOpts)
  }

  decimalsEnabled() {
    return !this.disableDecimalsValue
  }

  triggerFormChange() {
    const form = this.hiddenInputTarget.closest('form')
    if (!form) {
      return
    }
    form.dispatchEvent(new Event('change'))
  }

}
