import { BaseController } from 'stimulus-library';

/*
 * toggles the 'hide' class for multiple targets named 'content'
 */
export default class Toggle extends BaseController {
  static targets = ["content"];

  toggle(event) {
    if (this._reloadsPage(event.target)) {
      event.preventDefault();
    }

    this.contentTargets.forEach((t) => t.classList.toggle('hide'));
    event.currentTarget.classList.toggle('active');
  }

  // private

  _reloadsPage(element) {
    return (!!element.closest('a')) ||
      (!!element.closest('input') && element.type === 'submit');
  }
}
