import { Controller } from "@hotwired/stimulus"

/*
<turbo-stream action="append" target="project_1234">
  <template><meta data-controller="reload"></template>
</turbo-stream>

You can call `reload_turbo_frame 'frame_id'` in your controller to reload a frame
*/


export default class extends Controller {
  connect() {
    this.element.closest("turbo-frame")?.reload()
    this.element.remove()
  }
}
