import { Controller } from '@hotwired/stimulus';

// A controller that generates a pie SVG for its root element. Example:
// .pie{ data: { pie: occurrence_pie_chart_data(occurrence, stats), controller: 'pie'} }
// 
// It calls: app/assets/javascripts/lib/vendor/piechart.js
export default class extends Controller {
  connect() {
    new Pie(this.element)
  }
}