import { Controller } from '@hotwired/stimulus';
import { EventBus } from "../../lib/event_bus";

export default class extends Controller {

  static values = { name: String }

  connect() {
    EventBus.emit(`${this.nameValue}-presence:added`);
  }

  disconnect() {
    EventBus.emit(`${this.nameValue}-presence:removed`);
  }
}