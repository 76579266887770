import { Controller } from '@hotwired/stimulus'
import { get } from 'lodash-es'

export default class extends Controller {

  static values = {
    header: String,
    srcUrl: String,
    redirectPath: String
  }

  static targets = ['header', 'turboFrame']

  connect() {
    $(this.element).on('closed.fndtn.reveal', this.refreshSelector.bind(this))
  }

  onSelect(e) {
    console.error('no onSelect behaviour implemented')
  }

  redirectTo(_data) {
    console.error('no redirectTo behaviour implemented')
  }

  fireCustomEvent(zoneData) {
    console.error('no custom event defined')
  }

  refreshSelector(e) {
    // refresh the selector's src path e.g. after type has changed, or simply to set it back to default after closing the modal
    const turboFrameEl = this.element.querySelector('turbo-frame')
    if (!turboFrameEl) {
      return false
    }

    const turboRefreshPath = get(e, 'detail.refresh_url')
    if (turboRefreshPath && turboRefreshPath.length > 0) {
      turboFrameEl.setAttribute('src', turboRefreshPath)
    } else if (this.srcUrlValue && this.srcUrlValue.length > 0) {
      turboFrameEl.setAttribute('src', this.srcUrlValue)
    }
  }

  locationSelected(e) {
    // refresh modal header text (when needing to select a location first)
    if (this.hasHeaderTarget && this.hasHeaderValue) {
      this.headerTarget.textContent = this.headerValue
    }
  }

}
