import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { closeOnSubmit: Boolean }
  static get targets() {
    return ['modal']
  }

  connect() {
    this.toggleBodyScroll();
    if (!this.modalTarget.classList.contains('hide')) {
      this.focusFirst();
    }

    document.addEventListener("keydown", this.escapeListener.bind(this));
  }

  disconnect() {
    this.toggleBodyScroll();
    document.removeEventListener("keydown", this.escapeListener.bind(this));
  }

  backgroundClick(event) {
    if (this.modalTarget === event.target) {
      this.closeModal()
    }
  }
  
  toggleBodyScroll() {
    document.querySelector('body').classList.toggle("no-scroll")
  }

  submitForm(event) {
    const status = event.detail.fetchResponse.response.status
    if (status === 200 && this.closeOnSubmitValue) {
      this.closeModal()
    }
  }

  escapeListener(e) {
    if (e.key === "Escape") {
      if (!this.modalTarget.classList.contains('hide')) {
        this.closeModal();
      }
    }
  }

  focusFirst() {
    let elements = Array.from(this.element.querySelectorAll('input, select'));
    for (let i = 0; i < elements.length; i++) {
      let elem = elements[i];
      if ((elem.tagName === 'INPUT' && elem.type !== 'hidden') || elem.tagName === 'SELECT') {
        if (!elem.classList.contains('hidden')) {
          elem.focus();
          break;
        }
      }
    }
  }

  closeModal() {
    this.element.remove();
  }
}
