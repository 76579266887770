import { Controller } from '@hotwired/stimulus';
import { get } from 'lodash-es';

export default class extends Controller {
  connect() {
    this.element.addEventListener('keydown', this.preventSubmission.bind(this))
  }

  preventSubmission(e) {
    if (get(e, 'keyCode') === 13) {
      e.preventDefault()
    }
  }

}