import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    delay: Number,
    url: String
  }

  connect() {
    this.refreshAfterDelay()
  }

  refreshAfterDelay() {
    if (this.delayValue > 0) {
      setTimeout(this.reloadUrl, this.delayValue)
    } else {
      this.reloadUrl()
    }
  }

  reloadUrl() {
    if (this.hasUrlValue) {
      window.location.href = this.urlValue
    } else {
      window.location.reload()
    }
  }
}