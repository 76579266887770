import { Controller } from "@hotwired/stimulus"
import { clone, get, set, camelCase, isObject, isArray, reduce } from "lodash-es"
import { Chart, registerables } from 'chart.js'
import 'chartjs-adapter-date-fns'
Chart.register(...registerables)

export default class extends Controller {

  static values = {
    graphData: Object
  }

  connect() {
    this.graphDataValue = this.camelCaseKeys(this.graphDataValue)
    this.generateChart()
  }

  generateChart() {
    this.chart = new Chart(this.element, this.chartOptions())

    if (get(this.graphDataValue, 'options.plugins.externalLegend') === false) {
      this.renderLegend();
    }
  }

  camelCaseKeys(obj) {
    if (!isObject(obj)) {
      return obj;
    } else if (isArray(obj)) {
      return obj.map((v) => this.camelCaseKeys(v));
    }
    return reduce(obj, (r, v, k) => {
      return {
        ...r,
        [camelCase(k)]: this.camelCaseKeys(v)
      };
    }, {});
  }

  navigate(evt) {
    if (!this.navigationEnabled()) {
      return
    }

    const points = this.chart.getElementsAtEventForMode(evt, 'nearest', { intersect: true }, true)
    if (points.length) {
      const datasetIndex = get(points, '[0].index')
      const url = this.dataUrls[datasetIndex]
      if (url) {
        if (url.startsWith('#')) {
          location.hash = url.replace('#', '')
          const link = document.querySelector(`a[href="${url}"]`)
          if (link) {
            link.click() // workaround for foundation tabs flakiness
          }
        } else {
          location.href = url
        }
      }
    }
  }

  onHover(_e, chartElement) {
    this.element.style.cursor = chartElement[0] ? 'pointer' : 'default'
  }

  chartOptions(chart) {
    let opts = clone(this.graphDataValue)
    const yFormatter = get(opts, 'options.scales.y.ticks.callback')
    let tooltipFormatter = get(opts, 'options.plugins.tooltip.callbacks.label')
    if (yFormatter == 'format_currency') {
      set(opts, 'options.scales.y.ticks.callback', this.formatCurrency)
    }
    if (tooltipFormatter == 'format_currency' || yFormatter == 'format_currency') {
      set(opts, 'options.plugins.tooltip.callbacks.label', this.formatTooltipCurrency)
    } else if (tooltipFormatter == 'dataset_label_lookup') {
      set(opts, 'options.plugins.tooltip.callbacks.label', this.datasetLabelLookup)
    }

    if (this.navigationEnabled()) {
      set(opts, 'options.onHover', this.onHover.bind(this))
    }

    if (get(opts, 'type') == 'line') {
      // set defaults for line width, point styling and space around points
      set(opts, 'options.elements.point', {
        pointBorderWidth: 3,
        pointBorderColor: '#f1f0f8',
        pointRadius: 7
      })
      set(opts, 'options.elements.line', {
        borderWidth: 2
      })
    }

    set(opts, 'options.animation', false)

    return opts
  }

  renderLegend() {
    const legendContainer = this.element.nextElementSibling;
    if (legendContainer && legendContainer.classList.contains('chart-legend')) {
      var legendItems = this.chart.legend.legendItems;

      legendContainer.innerHTML = legendItems.map(item => {
        return `
          <span style="display: inline-block; margin-right: 10px; height: 26px;">
            <span style="background-color: ${item.fillStyle}; width: 12px; height: 12px; display: inline-block;"></span>
            ${item.text}
          </span>
        `;
      }).join('');
    }
  }

  navigationEnabled() {
    return this.dataUrls.length > 0
  }

  formatCurrency(value, index, ticks) {
    if (value == 0) {
      return ''
    }
    let formatter = Intl.NumberFormat('en', { notation: 'compact' })
    return `£${formatter.format(value)}`
  }

  formatTooltipCurrency(context) {
    let label = context.dataset.label || ''

    if (label) {
      label += ': '
    }
    let parsedVal = get(context, 'parsed.y')
    parsedVal ||= get(context, 'parsed')
    if (parsedVal !== null) {
      label += new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP', maximumFractionDigits: 0 }).format(parsedVal)
    }
    return label
  }

  datasetLabelLookup(tooltipItem) {
    var dataset = tooltipItem.dataset
    var index = tooltipItem.dataIndex
    return `${dataset.labels[index]}: ${dataset.data[index]}`
  }

  graphDataValueChanged() {
    this.dataUrls = get(this.graphDataValue, 'custom.urls', [])
  }

}