import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static outlets = ['modal']
  static values = { modalSelector: String }

  toggleModal(e) {
    e.preventDefault()
    const template = this.modalContent();
    const clonedElement = document.importNode(template, true);
    const originalElement = document.getElementById('modal')
    originalElement.parentNode.replaceChild(clonedElement, originalElement);
  }

  modalContent() {
    if (this.hasModalSelectorValue) {
      return document.querySelector(this.modalSelectorValue).content;
    }
    return this.element.nextElementSibling.content;
  }
}