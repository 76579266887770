import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["reveal", "status"]
  static values = { copyable: String }

  copy(event) {
    event.preventDefault()

    let copyTemp, icon, span
    copyTemp = document.createElement("textarea")
    copyTemp.value = this.copyableValue
    document.body.appendChild(copyTemp)
    copyTemp.select()

    if (document.execCommand("copy") && this.hasStatusTarget) {
      icon = document.createElement("i")
      icon.classList.add("fa", "fa-check", "success-text", "pr-0-5")
      this.statusTarget.appendChild(icon)

      span = document.createElement("span")
      span.innerHTML = "Copied to clipboard"
      this.statusTarget.appendChild(span)

      setTimeout(() => this.clearStatus(), 5000)
    }
    document.body.removeChild(copyTemp)
  }

  reveal(event) {
    event.preventDefault()

    if (!this.hasRevealTarget) return

    event.currentTarget.classList.add("hide")
    this.revealTarget.innerHTML = this.copyableValue
  }

  clearStatus() {
    if (!this.hasStatusTarget) return

    this.statusTarget.innerHTML = ""
  }
}
