import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["minValue", "maxValue"]

  connect() {
    this.updateSelectors();
  }

  updateSelectors() {
    const minSelect = this.minValueTarget;
    const maxSelect = this.maxValueTarget;
    const minValue = minSelect.value === '' ? null : parseInt(minSelect.value, 10);
    const maxValue = maxSelect.value === '' ? null : parseInt(maxSelect.value, 10);

    Array.from(maxSelect.options).forEach(option => {
      if (option.value !== '') {
        const optionValue = parseInt(option.value, 10);
        option.disabled = minValue !== null && optionValue < minValue;
      }
    });

    Array.from(minSelect.options).forEach(option => {
      if (option.value !== '') {
        const optionValue = parseInt(option.value, 10);
        option.disabled = maxValue !== null && optionValue > maxValue;
      }
    });
  }
}
