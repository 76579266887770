import { Controller } from "@hotwired/stimulus"
import { get } from 'lodash-es'

export default class extends Controller {

  static targets = ["input", "display", "reset"]

  zoneSelected(e) {
    // handle custom event triggered from the zone selector modal
    e.preventDefault()

    const selectedZoneId = get(e, 'detail.zone.id')
    if (this.hasInputTarget) {
      this.inputTarget.value = selectedZoneId
    }
    if (this.hasDisplayTarget) {
      this.displayTarget.textContent = get(e, 'detail.zone.path_name')
    }

    if (this.hasResetTarget) {
      if (selectedZoneId) {
        this.resetTarget.classList.remove('hide')
      } else {
        this.resetTarget.classList.add('hide')
      }
    }
  }

  reset(e) {
    if (this.hasResetTarget) {
      this.resetTarget.classList.add('hide')
    }
    window.dispatchEvent(new CustomEvent('location-zone-selected', { detail: { zone: { id: '', path_name: 'No zone selected' } } }))
  }
}
