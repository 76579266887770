import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = {
    collapse: Boolean
  }

  connect() {
    this.collapseValue = !this.collapseValue
  }

  toggleAll() {
    this.collapseValue = !this.collapseValue
    const sections = document.querySelectorAll('.accordion-section')
    sections.forEach(section => {
      !this.collapseValue ? section.classList.add("active") : section.classList.remove("active")
    });
    const buttonText = this.collapseValue ? "Expand all" : "Collapse all"
    this.element.innerHTML = buttonText
  }
}
