import { Controller } from '@hotwired/stimulus';
import { debounce } from 'lodash-es';
import { requestSubmit } from "../../lib/request_submit";

/*
 * For a filter query to submit automatically without the user hunting for a submit button add this to the form tag: `method: :get, remote: true, data: { controller: 'clear-form auto-submit-form'}`
 */
export default class extends Controller {

  get cssInputSelector() {
    return 'input:not(.no-auto-submit, .trix-input--dialog), textarea:not(.no-auto-submit)';
  }

  get inputElements() {
    return this.element.querySelectorAll(this.cssInputSelector);
  }

  get selectElements() {
    return this.element.querySelectorAll('select:not(.no-auto-submit)');
  }

  initialize() {
    this.debouncedHandler = debounce(this.handler.bind(this), 200);
  }

  connect() {
    this.inputElements.forEach(el => el.addEventListener('input', this.debouncedHandler));
    this.selectElements.forEach(el => el.addEventListener('input', this.handler.bind(this)));
  }

  disconnect() {
    this.inputElements.forEach(el => el.removeEventListener('input', this.debouncedHandler));
    this.selectElements.forEach(el => el.removeEventListener('input', this.handler));
  }

  keyUpSubmit() {
    debounce(this.handler.bind(this), 200);
  }

  handler(e) {
    requestSubmit(this.element);
  }

}

