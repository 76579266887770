import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ['input']
  static values = {
    yearSelector: String,
    monthSelector: String,
    daySelector: String
  }

  connect() {
    this.updateDateFields();
  }

  updateDateFields() {
    const datePickerInput = this.inputTarget;

    if (datePickerInput) {
      const date = datePickerInput.value;

      if (date.length > 0) {
        const md = moment(date);

        // Use the selectors to find and update the inputs
        document.querySelector(this.yearSelectorValue).value = md.year().toString();
        document.querySelector(this.monthSelectorValue).value = (md.month() + 1).toString(); // month is 0-indexed
        document.querySelector(this.daySelectorValue).value = md.date().toString();
      }
    }
  }
}
