import AssocationSelectorController from './association_selector_controller'
import { get, clone, replace } from 'lodash-es'

export default class extends AssocationSelectorController {

  onSelect(e) {
    e.preventDefault()

    const zoneJson = get(e, 'currentTarget.dataset.zone', '')
    if (zoneJson.length == 0) {
      console.error('No location zone data detected')
    } else {
      const zoneData = JSON.parse(zoneJson)
      if (this.hasRedirectPathValue) {
        this.redirectTo(zoneData)
      } else {
        this.fireCustomEvent(zoneData)
      }

      $(this.element).foundation('reveal', 'close')
    }
  }

  redirectTo(zoneData) {
    let redirect = clone(this.redirectPathValue)

    const zoneId = get(zoneData, 'scoped_id', 0)
    if (zoneId > 0) {
      redirect = replace(redirect, ':zone_id', zoneId)
    } else {
      redirect = replace(redirect, '/:zone_id', '')
    }

    const locationId = get(zoneData, 'location.scoped_id', 0)
    if (locationId > 0) {
      redirect = replace(redirect, ':location_id', locationId)
    } else {
      redirect = replace(redirect, '/:location_id', '')
    }

    window.location.href = redirect
  }

  fireCustomEvent(zoneData) {
    window.dispatchEvent(new CustomEvent('location-zone-selected', { detail: { zone: zoneData } }))
  }

}
