import { Controller } from "@hotwired/stimulus"
import { get } from 'lodash-es'

export default class extends Controller {

  static targets = ['item']

  connect() {
    window.addEventListener('turbo--list-item:deleted', this.itemDeleted.bind(this))
  }

  itemDeleted(e) {
    this.itemTargets.forEach((itemEl) => {
      if (itemEl == e.target) {
        this.refresh()
        return true
      }
    })

    return false
  }

  refresh(e) {
    const parentFrame = this.element.closest('turbo-frame')
    if (!parentFrame) {
      return false
    }

    const turboPath = get(parentFrame, 'src')
    if (turboPath && turboPath.length > 0) {
      parentFrame.setAttribute('src', '')
      parentFrame.setAttribute('src', turboPath)
    }
  }

}