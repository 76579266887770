import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = {
    status: String,
    delayTimeout: { type: Number, default: 1000 }
  }

  connect() {
    if (this.statusValue == 'deleted') {
      setTimeout(() => this.dispatch('deleted'), this.delayTimeoutValue)
    }
  }

}