import { Controller } from "@hotwired/stimulus";
import Tribute from "tributejs";
import Trix from "trix";

export default class extends Controller {
  static targets = ["field"];
  static values = { articlesEndpoint: String };

  triggerSymbol = ">>";

  connect() {
    if (this.hasFieldTarget) {
      this.fieldTarget.addEventListener('trix-initialize', this.initialiseEditor.bind(this))
    }
  }

  initialiseEditor(e) {
    this.editor = this.fieldTarget.editor;
    this.initialiseToolbarButton();
    this.initializeTribute();
  }

  disconnect() {
    this.tribute.detach(this.fieldTarget);
  }

  toolbarButtonClicked(e) {
    this.editor.recordUndoEntry('magic-link');
    this.editor.insertString(this.triggerSymbol);
  }

  initialiseToolbarButton() {
    let toolbar = this.editor.composition.delegate.toolbarController.element;
    let buttonGroups = toolbar.querySelectorAll(".trix-button-group");
    let buttons = buttonGroups.item(0).children;
    let lastButton = buttons[buttons.length - 1];
    let magicLinkTemplate = `
      <button 
        type="button" 
        class="trix-button trix-button--icon trix-button--icon-magic-link" 
        data-trix-attribute="magic-link" 
        data-trix-key="m"
        data-action="${this.identifier}#toolbarButtonClicked" 
        tabindex="-1" 
        title="Magic link to another article"
      >
        Magic Link
      </button>`;
    lastButton.insertAdjacentHTML("afterend", magicLinkTemplate);
  }

  initializeTribute() {
    this.tribute = new Tribute({
      allowSpaces: true,
      lookup: "title",
      trigger: this.triggerSymbol,
      values: this.fetchUsers.bind(this),
    });
    this.tribute.attach(this.fieldTarget);
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this);
    this.fieldTarget.addEventListener("tribute-replaced", this.replaced.bind(this));
  }

  fetchUsers(text, callback) {

    fetch(`${this.articlesEndpointValue}?q=${text}`)
      .then(response => response.json())
      .then(users => callback(users))
      .catch(error => callback([]));
  }

  replaced(e) {
    let item = e.detail.item;
    if (!item) {
      return;
    }
    let article = item.original;
    let attachment = new Trix.Attachment({
      sgid: article.sgid,
      content: article.content,
    });
    this.editor.insertAttachment(attachment);
    this.editor.insertString(" ");
  }

  _pasteHtml(_html, startPos, endPos) {
    let position = this.editor.getPosition();
    this.editor.setSelectedRange([position - (endPos - startPos), position]);
    this.editor.deleteInDirection("backward");
  }
}