import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    'clickRecipient'
  ]

  triggerClick(e) {
    e.preventDefault();

    this.clickRecipientTarget.click();
  }
}
