import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  static values = {
    defaultTarget: String
  }

  connect() {
    let links = this.element.querySelectorAll('a:not([href^="http://iam"]):not([href^="http://app.iam"]):not([href^="https://app.iam"]):not([href^="https://iam"]):not([href^="/"]):not([href^="#"]):not([href^="https://app.dev.iam"]):not([href^="http://app.dev.iam"]):not([href^="mailto:"])')

    let target
    links.forEach(link => {
      link.classList.add('external-link')
      target = link.getAttribute('target')
      if (this.defaultTargetValue && !target) {
        link.setAttribute('target', this.defaultTargetValue)
      }
    })
  }
}
